<script setup lang="ts">
    import { LazyCmsGoogleFormWidgetContainer } from '#components';
    import type { LocationQueryRaw } from '#vue-router';
    import type { GoogleFormWidgetData } from '~/@types/cms';
    import type { PageProps } from '~/@types/generic';
    import type { Product } from '~/graphql/generated';

    const props = defineProps<PageProps>();

    const nuxtApp = useNuxtApp();
    const appStore = useAppStore();
    const route = useRoute();
    const { getConfiguratorId, processProduct } = useProduct();
    const { page, pageId, breadcrumbItems, breadcrumbVisibility, isBike, bundles, upsellData, pageState } = usePage(props);
    const { locale, t, isoCode, iso2Country, isUS } = useLocales();
    const { addRecommended, addToCart } = useCart();
    const { bikeFinderLocaleUrl, handleBikeFinder } = useBikeFinder();
    const { previewEnabed } = usePreview();

    useHead({
        bodyAttrs: {
            class: computed(() => (isBike.value ? 'isBike' : '')),
        },
    });

    const product = computed(() => processProduct(page.value?.product as Partial<Product> | undefined));
    const { config, cartBusy } = useProductCard(product.value, {
        preselectSku: route.query.sku,
        variantUrl: route.query.variant,
    });

    let currentVariantId = '';
    const variant = computed(() => {
        if (!config) return undefined;

        const variant = config.selected.value;

        // FIXME: check for currentVariantId because the computed variant runs again after navigation 🤷🏼‍♀️
        if (import.meta.client && variant && currentVariantId !== variant.id) {
            currentVariantId = variant.id;
            const query: LocationQueryRaw = {
                ...route.query,
                variant: config.variantUrlQuery.value,
            };
            if (previewEnabed.value) query.preview = 'true';

            navigateTo(
                {
                    query,
                    hash: route.hash,
                },
                {
                    replace: true,
                },
            );

            nuxtApp.$webgains.pushEvent({
                pageType: 'product',
                productId: variant.sku,
                productPrice: variant.price,
                productName: product.value?.title,
                categoryId: product.value?.category?.[0],
            });
        }
        if (!config.isDigital) useProductMicrodata(variant, page.value?.product?.description);
        return variant;
    });
    const currentSku = computed(() => variant.value?.sku ?? '');

    /**
     * render the toggle between two products
     */
    const toggleId = computed(() => page.value?.content?.toggle as undefined | string);
    const bundleSelectorId = computed(() => page.value?.content?.bundleSelector as undefined | string);

    const hasTopControllerContent = computed(() => {
        const cmsContent = page.value?.content?.value?.value ?? '';
        return cmsContent !== '' && cmsContent !== '<p><br></p>';
    });

    /**
     * the old gallery data for the pdp - still in use as fallback and for accessory, spare parts and apparel
     */
    const variantImages = computed(() => {
        const v = page.value?.product?.variants?.items?.find((p: any) => p.sku === currentSku.value);

        return (
            v?.content?.gallery ||
            page.value?.content?.gallery ||
            (page.value?.product?.hero && [page.value.product.hero]) || [
                {
                    id: null,
                    src: v?.shopImage,
                    data: null,
                },
            ]
        );
    });

    const validationError = computed(() => {
        if (config?.validationError.value && config?.validationError.value !== '') {
            return t(config.validationError.value);
        }
        return undefined;
    });

    const gallery = useUspGallery(currentSku, page.value?.product?.usp);

    const giftCardImage = computed(() => {
        if (!config?.isGiftCard || !currentSku.value) return;
        return getAssetUrl(`${currentSku.value}`);
    });

    /**
     * we open a notify me with a google form
     */
    const notifyMe = () => {
        if (!appStore.website?.shop?.notify_me2) return;
        nuxtApp.$eventEmitter.emit('open-drawer', {
            title: t('availability.notifyMeTitle'),
            content: {
                component: LazyCmsGoogleFormWidgetContainer,
                props: {
                    data: {
                        codename: '',
                        id: '',
                        language: '',
                        form: appStore.website.shop.notify_me2,
                        selectedProductId: variant.value?.sku,
                        types: [],
                        background: 'white',
                        fullWidth: true,
                        success: appStore.website.shop.notify_me2_success,
                    },
                } as IWidgetProps<GoogleFormWidgetData>,
            },
        });
    };
    const availability = computed(() => {
        if (!variant.value || config?.isDigital) return;
        if (variant.value?.availabilityState === ProductAvailability.END_OF_LIFE) return 'endOfLife';
        if (variant.value?.quantityAvailable < 1) return 'soldOut';
        if (variant.value.quantityAvailable <= ProductQuantityAvailable.LOW_STOCK) return 'lowStock';
        return 'inStock';
    });

    const openBeacon = () => {
        if (window.Beacon) window.Beacon('open');
    };

    onMounted(async () => {
        if (page.value?.product) {
            const id = getConfiguratorId(page.value.product);

            try {
                const data = await getCollectionQueryData({
                    id,
                });

                if (data != null) {
                    pageState.value.configurator = data.data.collection?.products?.items;
                }
            } catch (err) {}
        }

        if (bundles.value.length > 0) {
            try {
                const data = await useBundlesQueryData({
                    variables: {
                        ids: bundles.value,
                        locale: locale.value,
                        preview: previewEnabed.value,
                    },
                }).promise;

                const bs = data?.data.bundles || [];
                if (Array.isArray(bs) && bs.length > 0) {
                    const r: BundlesQuery_Bundle[] = [];
                    for (const b of bs) {
                        if (b.availableForSale) {
                            r.push(b);
                        }
                    }
                    pageState.value.bundleData = r;
                    // console.log('got bundle data', this.bundleData)
                }
            } catch (err) {}
        }

        if (upsellData.value && upsellData.value.length > 0 && product.value) {
            addRecommended(product.value.id, upsellData.value);
        }

        if (nuxtApp.$gtm) {
            await wait(20);
            nuxtApp.$gtm.ecommerceProductPage(page.value, product.value);
        }

        if (isUS && product?.value) {
            const productUrl = location.href;
            stampedInitRichSnippets(product.value.title, productUrl);
        }
    });

    onUnmounted(async () => {
        stampedUnloadRichSnippets();
    });
</script>
<template>
    <div :class="[{ 'lg:min-h-screen-desktop': gallery.length > 0 }]">
        <atm-breadcrumbs
            :items="breadcrumbItems"
            :visibility="breadcrumbVisibility" />
        <atm-grid
            full-width
            no-margin
            class="pb-4 md:pb-8">
            <lazy-atm-heading
                v-if="page?.product?.title"
                level="h1"
                size="md"
                class="col-span-2 mx-6 md:col-span-12 md:mx-8 lg:sr-only">
                <renderer-html :content="woomTextFormat(page.product.title)" />
            </lazy-atm-heading>

            <!-- Full width gallery until lg -->
            <lazy-mol-gallery
                v-if="gallery.length > 0"
                :data="gallery"
                :redCharity="variant?.redCharity"
                class="col-span-2 md:col-span-12 lg:col-span-8 lg:ml-8">
                <mol-product-labels
                    v-if="product && variant"
                    :labels="product.labels"
                    :variant-labels="variant.labels"
                    class="left-6 top-2 md:left-8 lg:left-2" />
            </lazy-mol-gallery>
            <lazy-atm-image
                v-else-if="giftCardImage"
                :src="giftCardImage"
                sizes="100vw md:1024px lg:1264px"
                :modifiers="{ format: 'webp' }"
                alt="gift card product image"
                class="col-span-2 md:col-span-12 lg:col-span-8 lg:ml-8" />
            <lazy-mol-product-image
                v-else
                :images="variantImages"
                :is-bike="isBike">
                <mol-product-labels
                    v-if="product && variant"
                    :labels="product.labels"
                    :variant-labels="variant.labels"
                    class="left-6 top-2 md:left-8 lg:left-2" />
            </lazy-mol-product-image>

            <div class="col-span-2 mx-6 flex flex-col gap-6 max-md:pb-6 md:col-span-12 md:mx-8 lg:col-span-4">
                <lazy-atm-heading
                    v-if="page?.product?.title"
                    level="span"
                    size="md"
                    class="max-lg:hidden">
                    <renderer-html :content="woomTextFormat(page.product.title)" />
                </lazy-atm-heading>

                <atm-stamped-stars :data-id="product?.productId" />

                <div v-if="page?.product?.description">
                    <renderer-html :content="woomTextFormat(page.product?.description)" />
                </div>

                <div
                    v-if="availability && $t(`availability.${availability}.short`)"
                    class="font-mono">
                    <span :class="['availability', availability]">
                        {{ $t(`availability.${availability}.short`) }}
                    </span>
                    {{ $t(`availability.${availability}.long`) }}
                </div>

                <lazy-mol-product-toggle
                    v-if="toggleId"
                    :toggle-id="toggleId" />

                <lazy-org-product-options
                    v-if="config?.options"
                    :config="config"
                    isPDP
                    :redCharity="variant?.redCharity" />

                <lazy-mol-bundle-selector
                    v-if="bundleSelectorId"
                    :bundle-selector-id="bundleSelectorId" />

                <mol-product-price
                    :price="variant?.price"
                    :compare-at-price="variant?.compareAtPrice"
                    :product-price-label="page?.content?.productPriceLabel"
                    show-border
                    alignment="right"
                    stacked
                    size="lg" />

                <lazy-atm-affirm
                    v-if="isUS && variant?.canBeOrdered && variant.priceNumeric > 0"
                    :amount="variant.priceNumeric" />

                <lazy-atm-klarna
                    v-else-if="!isUS && variant?.canBeOrdered && variant.priceNumeric > 0"
                    :amount="variant.priceNumeric"
                    :region="iso2Country"
                    :iso-code="isoCode" />

                <!-- add to cart button with logic -->
                <div :class="[{ 'bottom-0 left-0 right-0 bg-white max-md:fixed max-md:z-lg max-md:px-6 max-md:py-4 max-md:shadow-md-top': isBike }]">
                    <div
                        v-if="isBike"
                        class="mb-3 flex justify-between gap-2 md:hidden">
                        <div class="space-y-1">
                            <div v-if="page?.product?.title">
                                <renderer-html :content="woomTextFormat(page.product.title)" />
                            </div>
                            <atm-price
                                :price="variant?.price"
                                :compare-at-price="variant?.compareAtPrice"
                                size="md" />
                        </div>
                        <button
                            class="flex items-center gap-1 underline"
                            @click="openBeacon">
                            <span>{{ $t('cta.questions') }}</span>
                            <woom-icon-help-bubble class="w-6" />
                        </button>
                    </div>
                    <div
                        v-if="validationError"
                        class="rounded bg-woom-grey p-4 text-center text-woom-red">
                        {{ $t(validationError) }}
                    </div>
                    <lazy-atm-button
                        v-if="
                            config &&
                            (variant?.availabilityState === ProductAvailability.PREORDER ||
                                variant?.availabilityState === ProductAvailability.AVAILABLE)
                        "
                        block
                        :loading="cartBusy"
                        :disabled="!unref(config.valid)"
                        @click="addToCart([config?.current], undefined, 'product_page')">
                        {{ variant?.availabilityState === ProductAvailability.PREORDER ? $t('cta.cart.addPreorder') : $t('cta.cart.add') }}
                        <template #icon>
                            <woom-icon-add-to-cart />
                        </template>
                    </lazy-atm-button>
                    <div
                        v-else-if="config && variant?.availabilityState === ProductAvailability.NOTIFY_ME"
                        class="flex flex-col gap-4 rounded-md bg-woom-grey p-4 text-center">
                        <span class="px-4 text-center">{{ $t('availability.notifyMe') }}</span>
                        <lazy-atm-button
                            block
                            styling="solid-secondary"
                            @click="notifyMe">
                            {{ $t('cta.email') }}
                        </lazy-atm-button>
                    </div>
                    <div
                        v-else
                        class="w-full rounded bg-woom-grey p-4 text-center">
                        {{ variant?.availabilityInfo }}
                    </div>
                </div>

                <!-- payment icon display -->
                <atm-shop-icons icon-type="payment" />

                <!-- product options -->
                <div
                    v-if="isBike"
                    class="flex flex-col gap-4 rounded bg-woom-grey p-4 font-mono text-xs">
                    <div class="flex items-center gap-4">
                        <woom-icon-for-the-bike class="h-6 w-6 shrink-0" />
                        <div class="flex shrink grow flex-col gap-2">
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.wheels"
                                :value="product?.bikeDetail?.wheels.value"
                                :label="product?.bikeDetail?.wheels.label" />
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.weight"
                                :value="product?.bikeDetail?.weight.value"
                                :label="product?.bikeDetail?.weight.label" />
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.features"
                                :value="product?.bikeDetail?.features.value"
                                :label="product?.bikeDetail?.features.label" />
                        </div>
                    </div>
                    <hr class="border-woom-grey-mid" />
                    <div class="flex items-center gap-4">
                        <woom-icon-for-the-kid class="h-6 w-6 shrink-0" />
                        <div class="flex shrink grow flex-col gap-2">
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.age"
                                :value="product?.bikeDetail?.age.value"
                                :label="product?.bikeDetail?.age.label" />
                            <lazy-atm-product-detail-line
                                v-if="product?.bikeDetail?.size"
                                :value="product?.bikeDetail?.size.value"
                                :label="product?.bikeDetail?.size.label" />
                        </div>
                    </div>
                    <template v-if="bikeFinderLocaleUrl">
                        <hr class="border-woom-grey-mid" />
                        <div class="flex items-center gap-4">
                            <nuxt-link
                                :to="bikeFinderLocaleUrl"
                                @click="handleBikeFinder"
                                class="flex cursor-pointer items-center gap-4 underline transition-colors hover:text-woom-red">
                                <woom-icon-size-guide class="h-6 w-6 shrink-0 fill-current"></woom-icon-size-guide>
                                {{ $t('bikefinder.label') }}
                            </nuxt-link>
                        </div>
                    </template>
                </div>
            </div>
        </atm-grid>
    </div>

    <!-- render the generic content -->
    <cms-content-controller
        v-if="hasTopControllerContent"
        :data="page?.content.value"
        :page-id="pageId" />

    <lazy-mol-stamped-main-widget
        v-if="isUS"
        :product-id="product?.productId"
        :shop-title="page?.product?.shopTitle"
        :image-url="gallery[0]?.src"
        :description="page?.product?.description"
        :product-sku="page?.product?.handle"
        :product-type="product?.productType" />
</template>
<style scoped>
    .availability::before {
        @apply align-middle text-3xl leading-3 content-['\2022'];
    }
    .inStock {
        @apply text-woom-green;
    }
    .soldOut,
    .endOfLife {
        @apply text-woom-red;
    }
    .lowStock {
        @apply text-woom-orange-flame;
    }
</style>
